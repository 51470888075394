import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import LayoutEnglish from "../components/layoutEnglish"
import Seo from "../components/seo"

import downloadAppApple from "../images/download_app_apple.png"
import downloadAppGoogle from "../images/download_app_google.png"
import cityStoriesApp from "../images/citystories_app.jpg"
import qrAppStore from "../images/qr_app_store.png"
import qrPlayStore from "../images/qr_play_store.png"

const DownloadPage = () => (
  <LayoutEnglish>
    <Seo title="City Stories" />
    <div style={{ display: `flex`, paddingTop: `80px`, paddingBottom: `120px` }}>
      <div style={{ width: `65%`, paddingRight: `2%` }}>
        <div>
          Hello,<br/>
          <br/>
          Thanks for visiting our citystories_bnx website. CITY STORIES is a project of <a href="https://www.bonn.de/stadtmuseum">Stadtmuseum Bonn</a> and <a href="https://www.fringe-ensemble.de">fringe ensemble</a>, with which we would like to offer you a new sight of the city. CITY STORIES is based on the idea that the history of a city is made up of the stories of its inhabitants.<br/>
          <br/>
          We have developed an app that helps you navigate through parts of the urban area of Bonn to various places and locations. Using simple navigation, you will find signs designed by us. Within a radius of a few metres around the signs, you will hear stories via your smartphone or tablet that have taken place at that exact location. All the stories were donated by citizens of Bonn. You'll find out surprising, funny, touching and certainly new information about the history of our city.<br/>
          <br/>
          The app can be downloaded via QR code from every signpost, from studio_bnx in Bonn's city centre and directly from this page. At launch, the app contains a total of 50 places and locations and just as many stories.<br/>
          <br/>
          You can get an overview here on the website, listen to or read the stories again and, above all, expand our collection with a story of yours that you have experienced at a specific place in Bonn.<br/>
          <br/>
          In the app you will also find the possibility to donate your story to the project and thus to the City Museum.<br/>
          <br/>
          citystories_bnx are part of the reinvention of the <a href="https://www.bonn.de/stadtmuseum">Stadtmuseum Bonn</a>. Together with you, we want to get to know Bonn. We are looking for your stories and memories. The intention is to creae an open location, by everyone and for everyone. We want to start a dialogue about life in Bonn. It's your city, your story and your museum!<br/>
          <br/>
          <Link to='/en/karte'>Now let's go and have fun discovering Bonn</Link>.
        </div>
      </div>
      <div style={{ width: `33%` }}>
        <div style={{ maxWidth: `95%` }}>
          <img style={{ maxWidth: `95%` }} src={cityStoriesApp} alt="City Stories App" /><br/>
        </div>
        <div style={{ maxWidth: `95%`, display: `flex`, alignItems: `center` }}>
          <a href="https://apps.apple.com/us/app/citystories-bnx/id1629714118"><img style={{ maxWidth: `90%` }} src={downloadAppApple} alt="Get City Stories App for iOS" /></a>
          <img style={{ maxWidth: `25%` }} src={qrAppStore} alt="City Stories App QR Code iOS" />
        </div>
        <div style={{ maxWidth: `95%`, display: `flex`, alignItems: `center` }}>
          <a href="https://play.google.com/store/apps/details?id=com.Fehime.citystories_bnx"><img style={{ maxWidth: `90%` }} src={downloadAppGoogle} alt="Get City Stories für Goole Android" /></a>
          <img style={{ maxWidth: `25%` }} src={qrPlayStore} alt="City Stories App QR Code Android" />
        </div>
      </div>
    </div>
  </LayoutEnglish>
)

export default DownloadPage
